import { React, useState } from 'react';
import { Link } from 'react-router-dom';
//API
import API from '../../API';
//Components
import Spinner from '../Spinner';
import GetText from '../GetText';
//Hook
import { useUserlistFetch } from '../../hooks/useUserlistFetch';
// Styles
import { Wrapper } from './Userlist.styles';

const Userlist = (userHash) => {

    const {
        state,
        loading, 
        error
    } = useUserlistFetch(userHash);

    if(loading) return <Spinner />
    if(error) return <div>Something went wrong...</div>

    var data = state.results[0].userList;

    return (
        <>
            <Wrapper>
                {
                    Object.keys(data).map((a) => (
                        <div key={data[a].userId}>

                            <br/><hr/>
                            userId: {data[a].userId}
                            <br/>
                            userName: <Link to={'/@'+data[a].userName}>{data[a].userName}</Link>
                            <br/>
                            Register Date: {data[a].createDate}
                            <br/>
                            Banned: {(data[a].userBanned == true) ? <b>true</b> : 'false' }
                            <hr/><br/>
                        </div>
                     ))
                }
            </Wrapper>
        </>
    )
};

export default Userlist;
import styled from "styled-components";

export const Wrapper = styled.div`
width:100%;
max-width: var(--maxWidth);
margin:auto;
padding: var(--padding-left-right);

.post-wrapper {
    margin: 12px 0;
}
`;

export const Content = styled.div`
display:inline-block;
padding: var(--padding-left-right);
vertical-align:top;
`;
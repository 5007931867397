import React, { useState, useEffect } from 'react';
//API
import API from '../../API';
// Config
import { USER_IMG_URL } from '../../config'
//Components
import Button from '../Button'
import GetText from '../GetText';
import Input from '../Input';
import Textarea from '../Textarea';
import Spinner from '../Spinner';
import UserImage from '../UserImage';
import UserDefaultImage from '../../images/user/profile-img-default.png'
//Styles
import { Wrapper } from './Moderation.style';
import UserFunctions from '../UserFunctions';

const Moderation = ({ paramPostId, paramAnswerId, paramUserHash, answer, post, paramUserId }) => {


    const [permissions, setPermissions] = useState();
    const [reportBox, setReportBox] = useState(false);
    const [answerBox, setAnswerBox] = useState(false);
    const [moderationBox, setModerationBox] = useState(false);
    const [userBox, setUserBox] = useState(false);

    const [postId, setPostId] = useState(0);
    const [postUserId, setPostUserId] = useState(0);
    const [postDeleted, setPostDeleted] = useState(false);
    const [postTitle, setPost] = useState(post);
    const [postAnswer, setAnswer] = useState(answer);

    const [reported, setReported] = useState(false);
    const [reportStep, setReportStep] = useState(0);

    const [answerId, setAnswerId] = useState(0);
    const [answerUserId, setAnswerUserId] = useState(0);
    const [answerCanDelete, setAnswerCanDelete] = useState(false);
    const [answerDeleted, setAnswerDeleted] = useState(false);

    const [userId, setUserId] = useState(0);
    const [userName, setUserName] = useState(0);
    const [userEmail, setUserEmail] = useState('');
    const [userImgUrl, setUserImgUrl] = useState('');

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleInput = e => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        if(name === 'post') setPost(value);
        if(name === 'answer') setAnswer(value);

    };

    const constModeration = async (GetAction, paramPostId, paramAnswerId, paramUserHash, GetPostTitle, GetPostAnswer, paramUserId) => {

        try {
            setError(false);
            setLoading(true);
    
            const data = await API.moderation(GetAction, paramPostId, paramAnswerId, paramUserHash, null, GetPostTitle, GetPostAnswer, paramUserId);

            //Check permissions
            if(data.results[0].permissions) {
                setPermissions(data.results[0].permissions);
            }

            //Check reported
            if(data.results[0].reported) {
                setReported(true);
            }

            //Check post
            if(data.results[0].postData) {
                setPostId(data.results[0].postData[0].postId);
                setPostUserId(data.results[0].postData[0].postUserId);
            }

            //Check answer
            if(data.results[0].answerData) {
                setAnswerId(data.results[0].answerData[0].answerId);
                setAnswerUserId(data.results[0].answerData[0].answerUserId);
                setAnswerCanDelete(data.results[0].answerData[0].answerCanDelete);
            }

            //Check User
            if(data.results[0].userData) {
                console.log(data.results[0].userData[0])
                setUserId(data.results[0].userData[0].userId);
                setUserName(data.results[0].userData[0].userName);
                setUserEmail(data.results[0].userData[0].userEmail);
                setUserImgUrl(data.results[0].userData[0].userImgUrl);
            }

            //Action
            if(GetAction == 'update') {
                if(data.results[0].success == true) {
                    if(GetPostTitle) {

                    }
                    if(GetPostAnswer) {

                    }
                    setModerationBox(false);
                }
            }

            if(GetAction == 'delete') {
                if(data.results[0].success == true) {
                    if(paramPostId) {
                        setPostDeleted(true);
                        setPost(null);
                    }
                    if(paramAnswerId) {
                        setAnswerDeleted(true);
                        setAnswer(null)
                    }
                    setModerationBox(false);
                }
            }

            if(GetAction == 'report') {
                if(data.results[0].success == true) {
                    setReported(true);
                }
            }

        } catch (error) {
            setError(true);
        }
        setLoading(false);
    };

    // Load More
    useEffect(() => {
        if(loading == false) {
            return;
        }
        else
        {
            constModeration(null, paramPostId, paramAnswerId, paramUserHash, null, null, paramUserId); //Check Permissions and Post/Answer Data
        }

    }, [loading, paramPostId, paramAnswerId, paramUserHash, paramUserId]);


    if(loading) return <Spinner />
    if(error) return <div>Something went wrong...</div>

    return (
        <>
            {
                //Logged in
                (UserFunctions.getSessionHash()) ? (
                    <>
                        {
                            //Permissions -1
                            (permissions == -1) ? (
                                <Wrapper>
                                    {
                                        //Dropdown
                                        //(answerId || postId) ? 
                                            <div className="dropdownModeration">
                                                <span><i className="fa-solid fa-ellipsis"></i></span>
                                                <div className="dropdownModeration-content">
                                                    {
                                                        (answerId && answerUserId == UserFunctions.getUserId() && answerDeleted == false && answerCanDelete == true) ?
                                                            <div onClick={() => { setAnswerBox(true) }} ><GetText textName={'deleteAnswer'} /></div>
                                                        :null
                                                    }
                                                    {
                                                        (answerId || postId) ? 
                                                            <div onClick={() => { setReportBox(true) }} ><GetText textName={'report'} /></div>
                                                        :null
                                                    }
                                                    {
                                                        (postId || answerId) ? 
                                                            <div onClick={() => { setModerationBox(true) }}>Edit Post</div>
                                                        :null
                                                    }
                                                    {
                                                        (userId) ? 
                                                            <div onClick={() => { setUserBox(true) }}>Edit User</div>
                                                        :null
                                                    }
                                                </div>
                                            </div>
                                        //:null
                                        //Dropdown End
                                    }
                                    {
                                        //report post
                                        (reported == false) ?
                                            <>
                                                <div className={ `${(reportBox == true) ? 'moderationBoxWrapper moderationBoxWrapperActive' : 'moderationBoxWrapper'}` }>
                                                    <div className='moderationBox'>
                                                        {
                                                            (reportStep === 0) ?
                                                                <>
                                                                    <h2><GetText textName={'reportBoxTitle'} /></h2>
                                                                    <Button callback={() => {setReportStep(2)} } text={<GetText textName={'yes'} />} />
                                                                    <Button callback={() => { {setReportBox(false)} {setReportStep(0)} }} text={<GetText textName={'no'} />} />
                                                                </>
                                                            :null
                                                        }
                                                        {
                                                            (reportStep === 1) ?
                                                                <>
                                                                    <h2><GetText textName={'moderationBoxReasonTitle'} /></h2>
                                                                    <Button callback={() => {setReportStep(2)} } text={<GetText textName={'send'} />} />
                                                                    <Button callback={() => { {setReportBox(false)} {setReportStep(0)} }} text={<GetText textName={'dismiss'} />} />
                                                                </>
                                                            :null
                                                        }
                                                        {
                                                            (reportStep === 2) ?
                                                                <>
                                                                    <h2><GetText textName={'moderationBoxSuccessTitle'} /></h2>
                                                                    <Button callback={async () => { {await constModeration('createReport', postId, answerId, UserFunctions.getSessionHash());} {setReportBox(false)} }} text={<GetText textName={'dismiss'} />} />
                                                                </>
                                                            :null
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        :null
                                        //report post end
                                    }
                                    {
                                        //answer delete
                                        (answerDeleted == false) ?
                                            <>
                                                <div className={ `${(answerBox == true) ? 'moderationBoxWrapper moderationBoxWrapperActive' : 'moderationBoxWrapper'}` }>
                                                    <div className='moderationBox'>
                                                        {
                                                            <>
                                                                <h2><GetText textName={'deleteAnswerTitle'} /></h2>
                                                                <Button callback={async () => { {await constModeration('deleteAnswer', null, answerId, UserFunctions.getSessionHash());} }} text={<GetText textName={'yes'} />} />
                                                                <Button callback={() => { {setAnswerBox(false)} }} text={<GetText textName={'no'} />} />
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        :null
                                        //answer delete end
                                    }
                                    {
                                        //post
                                        (moderationBox == true) ?
                                            <div className='moderationBoxWrapper moderationBoxWrapperActive'>
                                                <div className='moderationBox'>
                                                {
                                                    ((postId) && (postDeleted == false)) ? 
                                                        <Button callback={async () => {await constModeration('delete', postId, null, UserFunctions.getSessionHash());} } text={<GetText textName={'deletePost'} /> } />
                                                    :null
                                                }
                                                {
                                                    (answerId && (answerDeleted == false)) ?
                                                        <Button callback={async () => {await constModeration('delete', null, answerId, UserFunctions.getSessionHash());} } text={<GetText textName={'deleteAnswer'} /> } />
                                                    :null
                                                }
                                                {
                                                ((postId) && (postDeleted == false)) ? 
                                                    <>
                                                        <span>
                                                            Post Id: {postId}
                                                        </span>
                                                        <Input value={postTitle} name='post' onChange={handleInput} />
                                                        <Button callback={async () => {await constModeration('update', postId, null, UserFunctions.getSessionHash(), postTitle, null);} } text={<GetText textName={'save'} /> } />
                                                    
        <br></br>

        </>
                                                :null
                                                }
                                                {
                                                (answerId && (answerDeleted == false)) ?
                                                    <>
                                                        <span>
                                                            Answer Id: {answerId}
                                                        </span>
                                                        <Textarea value={postAnswer} name='answer' onChange={handleInput} />
                                                        <Button callback={async () => {await constModeration('update', null, answerId, UserFunctions.getSessionHash(), null, postAnswer);} } text={<GetText textName={'save'} /> } />
                                                        <br></br>
                                                    </>
                                                    :null
                                                }
                                                {
                                                    <Button callback={() => { setModerationBox(false) }} text={<GetText textName={'dismiss'} />} />
                                                }
                                                </div>
                                            </div>
                                        :null
                                        //Post End
                                    }
                                    {
                                        //post
                                        (userBox == true) ?
                                            <div className='moderationBoxWrapper moderationBoxWrapperActive'>
                                                <div className='moderationBox'>
                                                {
                                                    ((postId) && (postDeleted == false)) ? 
                                                        <Button callback={async () => {await constModeration('delete', postId, null, UserFunctions.getSessionHash());} } text={<GetText textName={'deletePost'} /> } />
                                                    :null
                                                }
                                                {
                                                    (answerId && (answerDeleted == false)) ?
                                                        <Button callback={async () => {await constModeration('delete', null, answerId, UserFunctions.getSessionHash());} } text={<GetText textName={'deleteAnswer'} /> } />
                                                    :null
                                                }
                                                {
                                                ((postId) && (postDeleted == false)) ? 
                                                    <>
                                                        <span>
                                                            Post Id: {postId}
                                                        </span>
                                                        <Input value={postTitle} name='post' onChange={handleInput} />
                                                        <Button callback={async () => {await constModeration('update', postId, null, UserFunctions.getSessionHash(), postTitle, null);} } text={<GetText textName={'save'} /> } />
                                                    
        <br></br>

        </>
                                                :null
                                                }
                                                {
                                                    (userId) ?
                                                    <>
                                                        <span>
                                                            userId: {userId}
                                                        </span>
                                                        <UserImage src={(userImgUrl) ? ( USER_IMG_URL + userImgUrl ) : ( UserDefaultImage )} alt='UserImage' className='profile-img'/>
                                                        <Button callback={async () => {await constModeration('deleteImage', null, null, UserFunctions.getSessionHash(), postTitle, null, userId);} } text={<GetText textName={'delete'} /> } />
                                                        <br></br>
                                                    </>
                                                    :null
                                                }
                                                {
                                                    <Button callback={() => { setUserBox(false) }} text={<GetText textName={'dismiss'} />} />
                                                }
                                                </div>
                                            </div>
                                        :null
                                        //Post End
                                    }
                                </Wrapper>
                            ):null
                        }
                    </>
                ):null
            }
        </>
    )
};

export default Moderation;
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
//API
import API from '../../API';
//Components
import GetText from '../GetText';
import Spinner from '../Spinner';
import Textarea from '../Textarea';
import Button from '../Button';
import Image from '../Image';
import ImageUpload from '../ImageUpload';
//Styles
import { Wrapper } from './CreateImage.style';
//Hook
import { useImagesFetch } from '../../hooks/useImagesFetch';
import UserFunctions from '../UserFunctions';
//Config
import { IMG_URL} from '../../config';

const CreateImage = ({ value, userHash }) => {

    const navigate = useNavigate();

    /* Get Images */
    const [imageObject, setImageObject] = useState('');
    const [imageObjectCounter, setImageObjectCounter] = useState();
    const [imageActive, setHashtagActive] = useState();

    const [imageMinAmount, setImageMinAmount] = useState();
    const [imageMaxAmount, setImageMaxAmount] = useState();

    const {
        state: dataImages,
        loading: imageLoading,
        error: imageError,
    } = useImagesFetch();

    if(dataImages[0] && !imageObject[0]) {
        setImageObject(dataImages);
    }
    
    if(imageObject) {

        if(imageObjectCounter) {
            imageObject[imageObjectCounter].active = imageActive;
        }

    }
    /* Get Images END */

    /* Image Create Stuff */
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if(!loading) {
            return;
        }

    }, [loading]);

    /* Image Create End */

    if(loading) return <Spinner />
    if(error) return <Wrapper>Something went wrong...</Wrapper>

    return (
        
        <Wrapper>
            {
                (errorMessage) ?
                <Wrapper>
                    <GetText textName={errorMessage} textStyle={'default'} />
                </Wrapper> :null
            }
			<div className="hashtag-create-wrapper">
                {
                    <>
                        <div>
                            <ImageUpload />
                        </div>
                        <div>
                            {
                                (imageObject) ? (
                                <h2><GetText textName='images' /></h2>
                                )
                                :
                                (
                                    <></>
                                )
                            }
                            {

                                (imageObject) ?
                                    Object.keys(imageObject).map((a) => (
                                        (imageObject[a].deleted != 1) ?
                                            <Link to={`/image/${imageObject[a].imageId}/${imageObject[a].fullFileName}`}>
                                                <Image key={imageObject[a].imageId}
                                                    src={IMG_URL + imageObject[a].fullFileName} alt='Image' />
                                            </Link>
                                        :null
                                    ))
                                :null

                            }
                        </div>
                    </>
                }
            </div>
        </Wrapper>
    )
};

export default CreateImage;
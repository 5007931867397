import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    position: fixed;
    width:100%;
    height:90px;
    top:0px;
    z-index:100;
    background: var(--color-bg-9);
    padding: var(--padding-left-right);
`;

export const Content = styled.div`
    width:100%;
    max-width: var(--maxWidth);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    margin: 0 auto;
    color: var(--color-5);

    a {
        color: var(--color-5);
        text-decoration:none;
    }
    span {
        margin: auto 10px;
    }
    .iconWrapper {

    }
    .adminIcon, .userIcon, .settingsIcon, .logoutIcon, .dropDownIcon {
        cursor:pointer;
        margin:0 10px;
        font-size:var(--fontBig);
        color: var(--color-1);

        :hover, :focus {
            opacity:0.75;
        }
    }
    .signin-button {
        background: var(--color-bg-2);
        color: var(--color-1);
        border-color: var(--border-color-1);
        cursor:pointer;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 6px;
        padding-bottom: 6px;
        border:1px solid;
        border-radius:0px !important;

        .signinIcon {
            color: var(--color-1);
            font-size:24px;
        }

        span {
            font-size:18px;
        }
    }
`;

export const LogoImg = styled.img`
    width: 175px;

    @media (max-width:500px){
        width: 150px;
    }
`;
import styled from "styled-components";

export const Wrapper = styled.div`
max-width: var(--maxWidth);
margin:auto;
margin-top:20px;
padding: var(--padding-left-right);

.post-wrapper {
    margin: 12px 0;
}
`;
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
//API
import API from '../../API';
//Components
import GetText from '../GetText';
import Spinner from '../Spinner';
import Textarea from '../Textarea';
import Button from '../Button';
import Hashtag from '../Hashtag';
//Styles
import { Wrapper } from './EditHashtag.styles';
//Hook
import { useHashtagsFetch } from '../../hooks/useHashtagsFetch';
import UserFunctions from '../UserFunctions';

const UpdateHashtag = ({ value, userHash }) => {

    /* Hashtag Stuff */
    const [hashtagObject, setHashtagObject] = useState('');
    const [hashtagObjectCounter, setHashtagObjectCounter] = useState();
    const [hashtagActive, setHashtagActive] = useState();

    const [hashtagMinAmount, setHashtagMinAmount] = useState();
    const [hashtagMaxAmount, setHashtagMaxAmount] = useState();


    const handleCategoryClick = e => {
        const objectCounter = e.currentTarget.name; //objectCounter

        if(objectCounter) {

            if(hashtagObject[objectCounter]) {

                if(hashtagObject[objectCounter].active) {

                    setHashtagObjectCounter(objectCounter);
                    setHashtagActive(false);
                }
                else
                {
                    setHashtagObjectCounter(objectCounter);
                    setHashtagActive(true);
                }
            }

        }
        setHashtagObject(null);
        setHashtagObject(hashtagObject);

    };

    const {
        state: dataHashtags,
        loading: hashtagLoading,
        error: hashtagError,
        subHashtag,
        setSubHashtag
    } = useHashtagsFetch();

    if(subHashtag != true) {
        setSubHashtag(true);
    }
    if(dataHashtags[0] && !hashtagObject[0]) {
        setHashtagObject(dataHashtags);
    }
    
    if(hashtagObject) {

        if(hashtagObjectCounter) {
            hashtagObject[hashtagObjectCounter].active = hashtagActive;
        }

    }
    /* Hashtag Stuff End */

    /* Hashtag Relation Load and Show */
    const {
        state: dataHashtagsRelation,
        loading: hashtagLoadingRelation,
        error: hashtagErrorRelation,
        hashtagSelectedName,
        setHashtagSelectedName,
    } = useHashtagsFetch();
    

    if(hashtagSelectedName != value) {
        setHashtagSelectedName(value);
    }
    /* Hashtag Relation Active Load and Show End */

    /* Hashtag Edit Stuff */
    const [title, setTitle] = useState(' ');
    const [imageId, setImageId] = useState(' ');
    const [hashtagOldName, setOldName] = useState(' ');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleInputHashtag= e => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        if(name === 'hashtagTextareaTitle') setTitle(value);
        if(name === 'hashtagTextareaImageId') setImageId(value);
        
    };

    const handleUpdateHashtagRelation = async () => {
        if(title) {
            try {
                setLoading(true)
                setError(false);
                setHashtagMinAmount(null)
                setHashtagMaxAmount(null)
                setErrorMessage(null)
        
                /* check some things */
                    var newTitle = title;
                    /* check if title ends with space */

                        if(/\s$/.test(newTitle)) {
                            var newTitle = (newTitle.substring(0, newTitle.length - 1))
                        }
                    /* check if title ends with space End */
                    
                    /* setTitle after checks if something changed */
                    if(newTitle != title) {
                        setTitle(newTitle);
                    }
                    /* setTitle after checks if something changed End */
                /* check some things end */

                    const data = await API.updateHashtag('updateHashtagRelation', UserFunctions.getSessionHash(), newTitle, hashtagObject);
                    
                    var hashtagName = data.results[0].hashtag;
                    var errorMessage = data.results[0].error;

                    if(hashtagName) { 
                        setLoading(false);
                        navigate('/category/'+hashtagName);
                        window.location.reload(); 
                    }

                    if(errorMessage) {
                        setLoading(false);
                        setErrorMessage(errorMessage)
                    }
                    if(data.results[0].hashtagMinAmount) {
                        setLoading(false);
                        setHashtagMinAmount(data.results[0].hashtagMinAmount)
                    }
                    if(data.results[0].hashtagMaxAmount) {
                        setLoading(false);
                        setHashtagMaxAmount(data.results[0].hashtagMaxAmount)
                    }


            } catch (error) {
                setLoading(false);
                setError(true);
            }
        }
    };


    const handleUpdateHashtagNameImageId = async () => {

        if(title) {
            try {
                setLoading(true)
                setError(false);
                setHashtagMinAmount(null)
                setHashtagMaxAmount(null)
                setErrorMessage(null)
        
                /* check some things */
                    var hashtagNewName = title;
                    /* check if title ends with space */

                        if(/\s$/.test(hashtagNewName)) {
                            var hashtagNewName = (hashtagNewName.substring(0, hashtagNewName.length - 1))
                        }
                    /* check if title ends with space End */
                /* check some things end */

                    const data = await API.updateHashtag('updateHashtagNameImageId', UserFunctions.getSessionHash(), hashtagOldName, hashtagObject, hashtagNewName, imageId);
                    
                    var hashtagName = data.results[0].hashtag;
                    var errorMessage = data.results[0].error;

                    if(hashtagName) { 
                        setLoading(false);
                        navigate('/category/'+hashtagName);
                    }

                    if(errorMessage) {
                        setLoading(false);
                        setErrorMessage(errorMessage)
                    }
                    if(data.results[0].hashtagMinAmount) {
                        setLoading(false);
                        setHashtagMinAmount(data.results[0].hashtagMinAmount)
                    }
                    if(data.results[0].hashtagMaxAmount) {
                        setLoading(false);
                        setHashtagMaxAmount(data.results[0].hashtagMaxAmount)
                    }


            } catch (error) {
                setLoading(false);
                setError(true);
            }
        }
    };

    const handleDeleteHashtag = async () => {
        if(title) {
            try {
                setLoading(true)
                setError(false);
                setErrorMessage(null)


                const data = await API.updateHashtag('deleteHashtag', UserFunctions.getSessionHash(), title, hashtagObject);
                    
                var success = data.results[0].success;
                var errorMessage = data.results[0].error;

                if(success) { 
                    setLoading(false);
                    navigate('/category');
                }

                if(errorMessage) {
                    setLoading(false);
                    setErrorMessage(errorMessage)
                }


            } catch (error) {
                setLoading(false);
                setError(true);
            }
        }
    };

        /* Hashtag Get Data */
        const {
            state: dataHashtag,
            loading: dataHashtagLoading,
            error: dataHashtagError,
            hashtagSelectedName: dataHashtagSelectedName,
            setHashtagSelectedName: setDataHashtagSelectedName,
            action: dataHashtagAction,
            setAction: setDataHashtagAction
        } = useHashtagsFetch();

        if(dataHashtagSelectedName != value) {
            setDataHashtagSelectedName(value);
        }

        if(dataHashtagAction != 'getHashtagData') {
            setDataHashtagAction('getHashtagData');
        }

        if(dataHashtag[0]) {
            if(imageId == ' ') {
                if(dataHashtag[0].imageId) {
                    setImageId(dataHashtag[0].imageId);
                }
            }
        }
        /* Hashtag Get Data End */

    useEffect(() => {
        if(!loading) {
            if(title) {
                return;
            }
        }

    }, [title, value, loading]);

    /* Hashtag Edit Stuff End */

    /* Fill Title Field with Search Value */
    if(title == ' ') {
        setTitle(value);
        setOldName(value);
    }
    /* Fill Title Field with Search Value End */




    if(loading) return <Spinner />
    if(error) return <Wrapper>Something went wrong...</Wrapper>


    return (
        
        <Wrapper>
            <> 
                <Link to='/category'><GetText textName={'back'} /></Link>
            </>
            {
                (errorMessage) ?
                <Wrapper>
                    <GetText textName={errorMessage} textStyle={'default'} />
                    {
                        (hashtagMinAmount) ?
                            <> <GetText textName={'minAmount'} textStyle={'default'} valueAfter={hashtagMinAmount} /> </>
                        :null
                    }
                    {
                        (hashtagMaxAmount) ?
                            <> <GetText textName={'maxAmount'} textStyle={'default'} valueAfter={hashtagMaxAmount} /> </>
                        :null
                    }
                </Wrapper> :null
            }
			<div className="hashtag-create-wrapper">
                {
                    <>
                        {
                            //Disabled HashtagReleation
                            



                            (dataHashtagsRelation) ?

                                Object.keys(dataHashtagsRelation).map((a) => (

                                    <Hashtag key={dataHashtagsRelation[a].hashtagId}
                                    text={dataHashtagsRelation[a].hashtag} />
                                    
                                ))

                            :null
                        }

                        <div className="hashtag-create-title"><GetText textName='hashtag' /></div>
                        <Textarea name="hashtagTextareaTitle" className='hashtag-create-textarea-title"' placeholder='' value={title} onChange={handleInputHashtag} />
                        {
                            (dataHashtagLoading) ?
                                <Spinner />
                            :
                                <Textarea name="hashtagTextareaImageId" className='hashtag-create-textarea-title"' placeholder='' value={imageId} onChange={handleInputHashtag} />
                        
                        }
                        <center><Button className='hashtag-create-button color-button hover' text={'Update Name & ImageId'} callback={handleUpdateHashtagNameImageId} /></center>
                        <center><Button className='hashtag-create-button color-button hover' text={'Update Relation'} callback={handleUpdateHashtagRelation} /></center>
                        <center><Button className='hashtag-create-button color-button hover' text={<GetText textName='delete' /> } callback={handleDeleteHashtag} /></center>
                        {
                            //Disabled HashtagReleation
                            
                            (hashtagObject) ? (
                                <h2><GetText textName='hashtags' /></h2>
                            )
                            :
                            (
                                <></>
                            )
                            
                        }
                        {
                            //Disabled HashtagReleation
                            
                            (hashtagObject) ?

                                Object.keys(hashtagObject).map((a) => (

                                    (hashtagObject[a].hashtag != value) ? // Do not list up the selected hashtag

                                            <Hashtag key={hashtagObject[a].hashtagId}
                                            active={ `${(hashtagObject[a].active == true) ? true : false}`}
                                            name={a}
                                            onClick={handleCategoryClick}
                                            text={ `${(hashtagObject[a].hidden == true) ? hashtagObject[a].hashtag+' [Hidden]' : hashtagObject[a].hashtag }`}
                                            disabled={ `${(hashtagObject[a].disabled == true) ? true : false}`} />
                                    :null
                                    
                                ))

                            :null
                            
                        } 
                    </>
                }
            </div>
        </Wrapper>
    )
};

export default UpdateHashtag;
import React from 'react';
import { Link } from 'react-router-dom';
//Components
import GetText from '../GetText';
//Functions
import UserFunctions from '../UserFunctions';
//Styles
import { Wrapper, Content } from './Footer.styles';

const Footer = () => {

    /* Language */

    const handleUserLanguage = e => {
        var valueUserLanguage = e.currentTarget.value;
        //UserLanguage
        if(valueUserLanguage != null) {
            UserFunctions.deleteUserLanguage();
            UserFunctions.setUserLanguage(valueUserLanguage);
            window.location.reload(true);//Refresh is actually necessary
        }
    }
  
    /* Language End */

    /* Darkmode */

    const handleDarkMode = e => {
        var valueDarkMode = e.currentTarget.value;
        //Darkmode
        if(valueDarkMode != null) {
            UserFunctions.setDarkMode(valueDarkMode);
            window.location.reload(true);//Refresh is actually necessary
        }
    }

    /* Darkmode End */

    return (
        <Wrapper id="footer">
            <Content>
                <Link to='/legal/about' className='hover'>{<GetText textName='about' />}</Link>
                <Link to='/legal/terms' className='hover'>{<GetText textName='terms' />}</Link>
                <Link to='/legal/privacy' className='hover'>{<GetText textName='privacy' />}</Link>
                <Link to='/faq' className='hover'>{<GetText textName='faq' />}</Link>

                {
                    ((UserFunctions.getUserLanguage() == 'de') || (UserFunctions.getUserLanguage() == 'de-DE')) ? 
                        <select onChange={handleUserLanguage}>
                            <option value='de'>Deutsch</option>
                            <option value='en'>English</option>
                        </select>
                    :
                        <select onChange={handleUserLanguage}>
                            <option value='en'>English</option>
                            <option value='de'>Deutsch</option>
                        </select>
                }
                <br></br>
                <br></br>
                {
                    (UserFunctions.getDarkMode() == 'true') ? 
                        <select onChange={handleDarkMode}>
                            <option value='true'>Darkmode On</option>
                            <option value='false'>Darkmode Off</option>
                        </select>
                    :
                        <select onChange={handleDarkMode}>
                            <option value='false'>Darkmode Off</option>
                            <option value='true'>Darkmode On</option>
                        </select>
                }

                <span>&copy; {new Date().getFullYear()} Libfast</span>
            </Content>
        </Wrapper>
    )
};

export default Footer;
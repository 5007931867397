import { React, useState } from 'react';
import { Link } from 'react-router-dom';
//API
import API from '../../API';
//Components
import Spinner from '../Spinner';
import GetText from '../GetText';
//Hook
import { useStatsFetch } from '../../hooks/useStatsFetch';
// Styles
import { Wrapper } from './Stats.styles';

const Stats = (userHash) => {

    const {
        state,
        loading, 
        error
    } = useStatsFetch(userHash);


    if(loading) return <Spinner />
    if(error) return <div>Something went wrong...</div>

    if(state) {
        var data = state.results[0];
    }

    return (
        <>
            <Wrapper>
                {
                    <ul>
                        <li><GetText textName={'userNotBannedAmount'} />: {data.userNotBannedAmount}</li>
                        <li><GetText textName={'userBannedAmount'} />: {data.userBannedAmount}</li>
                        <li><GetText textName={'userRegisteredTodayAmount'} />: {data.userRegisteredTodayAmount}</li>
                        <li><GetText textName={'userRegisteredYesterdayAmount'} />: {data.userRegisteredYesterdayAmount}</li>
                        <hr></hr>
                        <li><GetText textName={'postTodayAmount'} />: {data.postTodayAmount}</li>
                        <li><GetText textName={'postYesterdayAmount'} />: {data.postYesterdayAmount}</li>
                        <li><GetText textName={'postAlltimeAmount'} />: {data.postAlltimeAmount}</li>
                        <hr></hr>
                        <li><GetText textName={'answerTodayAmount'} />: {data.answerTodayAmount}</li>
                        <li><GetText textName={'answerYesterdayAmount'} />: {data.answerYesterdayAmount}</li>
                        <li><GetText textName={'answerAlltimeAmount'} />: {data.answerAlltimeAmount}</li>
                        <hr></hr>
                        <li><GetText textName={'voteTodayAmount'} />: {data.voteTodayAmount}</li>
                        <li><GetText textName={'voteYesterdayAmount'} />: {data.voteYesterdayAmount}</li>
                        <li><GetText textName={'voteAlltimeAmount'} />: {data.voteAlltimeAmount}</li>
                        <hr></hr>
                        <li><GetText textName={'voteLikeTodayAmount'} />: {data.voteLikeTodayAmount}</li>
                        <li><GetText textName={'voteLikeYesterdayAmount'} />: {data.voteLikeYesterdayAmount}</li>
                        <li><GetText textName={'voteLikeAlltimeAmount'} />: {data.voteLikeAlltimeAmount}</li>
                        <hr></hr>
                        <li><GetText textName={'voteDislikeTodayAmount'} />: {data.voteDislikeTodayAmount}</li>
                        <li><GetText textName={'voteDislikeYesterdayAmount'} />: {data.voteDislikeYesterdayAmount}</li>
                        <li><GetText textName={'voteDislikeAlltimeAmount'} />: {data.voteDislikeAlltimeAmount}</li>
                   </ul>
                }
            </Wrapper>
        </>
    )
};

export default Stats;
import React from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
// Config
import { IMG_URL } from '../config'
//Functions
import UserFunctions from './UserFunctions';
import Wrapper from './Wrapper'
// Components
import Reports from './Reports';
import Userlist from './Userlist';
import Stats from './Stats';
import Header from './Header';
import Footer from './Footer';
import { Wrapper as DashboardWrapper, Content } from './Dashboard/Dashboard.styles';
import GetText from './GetText';
import CreateHashtag from './CreateHashtag';
import EditHashtag from './EditHashtag';

const HashtagDashboard = () => {

    const navigate = useNavigate();

    const {
        hashtag
    } = useParams();


    return (
        <>
            <Header userName={UserFunctions.getUserName()} />
                <Wrapper>
                    <DashboardWrapper>
                        {
                            (hashtag) ?
                                <EditHashtag value={hashtag} />
                            :
                                <CreateHashtag />
                        }
                    </DashboardWrapper>
                </Wrapper>
            <Footer />
        </>
    )
};

export default HashtagDashboard;
import { React, useState } from 'react';
import { Link } from 'react-router-dom';
//API
import API from '../../API';
//Components
import Spinner from '../Spinner';
import GetText from '../GetText';
//Hook
import { useReportsFetch } from '../../hooks/useReportsFetch';
// Styles
import { Wrapper } from './Reports.styles';

const Reports = (userHash) => {

    const {
        stateReports,
        loading, 
        error
    } = useReportsFetch(userHash);


    const ReportSolved = async (valueReportId, valueReportSolved,) => {

        try {

            const updateReportSolved = await API.moderation(valueReportSolved, null, null, userHash.userHash, valueReportId);

            if(updateReportSolved.results[0].success === true) {

            }

        } catch (error) {

        }
    };

    
    const handleReportSolved = e => {
        var valueReportSolved = e.currentTarget.value;
        var valueReportId = e.currentTarget.name;

        if(valueReportSolved != null) {
            ReportSolved(valueReportId, valueReportSolved)
        }
    }




    if(loading) return <Spinner />
    if(error) return <div>Something went wrong...</div>
    if(!stateReports.results[0].permissions) return <></>

    var data = stateReports.results[0].reports;

    return (
        <>
            <Wrapper>
                {
                    (data) ?
                        Object.keys(data).map((a) => (
                            <div key={data[a].reportId}>

                                <br/><hr/>
                                Status: 
                                {
                                    (data[a].reportDeleted == 1) ? 
                                        <select name={data[a].reportId} onChange={handleReportSolved}>
                                            <option value='solvedReport'>Solved</option>
                                            <option value='notSolvedReport'>Not Solved</option>
                                        </select>
                                    :
                                        <select name={data[a].reportId} onChange={handleReportSolved}>
                                            <option value='notSolvedReport'>Not Solved</option>
                                            <option value='solvedReport'>Solved</option>
                                        </select>
                                }
                                <br/>
                                Report Id: {data[a].reportId}
                                <br/>
                                Report UserName: <Link to={'/@'+data[a].reportUserName}>{data[a].reportUserName}</Link>
                                <br/>
                                Report UserId: {data[a].reportUserId}
                                <br/>
                                Report Date: {data[a].reportDate}
                                <br/>
                                <br/>
                                <h3>Reported:</h3>
                                <br/>
                                Post Id: {data[a].reportPostId}
                                <br/>
                                Answer Id: {data[a].reportAnswerId}
                                <br/>
                                Post URL: <Link to={`/p/`+data[a].reportPostUrl}>{data[a].reportPostUrl}</Link>
                                <br/><hr/><br/>
                            </div>
                        ))
                    :null
                }
            </Wrapper>
        </>
    )
};

export default Reports;
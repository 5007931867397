import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
//API
import API from '../../API';
//Components
import GetText from '../GetText';
import Spinner from '../Spinner';
import Textarea from '../Textarea';
import Button from '../Button';
import Hashtag from '../Hashtag';
//Styles
import { Wrapper } from './CreateHashtag.style';
//Hook
import { useHashtagsFetch } from '../../hooks/useHashtagsFetch';
import UserFunctions from '../UserFunctions';

const CreateHashtag = ({ value, userHash }) => {

    const navigate = useNavigate();

    /* Hashtag Stuff */
    const [hashtagObject, setHashtagObject] = useState('');
    const [hashtagObjectCounter, setHashtagObjectCounter] = useState();
    const [hashtagActive, setHashtagActive] = useState();

    const [hashtagMinAmount, setHashtagMinAmount] = useState();
    const [hashtagMaxAmount, setHashtagMaxAmount] = useState();


    const {
        state: dataHashtags,
        loading: hashtagLoading,
        error: hashtagError
    } = useHashtagsFetch();

    if(dataHashtags[0] && !hashtagObject[0]) {
        setHashtagObject(dataHashtags);
    }
    
    if(hashtagObject) {

        if(hashtagObjectCounter) {
            hashtagObject[hashtagObjectCounter].active = hashtagActive;
        }

    }
    /* Hashtag Stuff End */

    /* Sub Hashtag Stuff */
    const [subHashtagObject, setSubHashtagObject] = useState('');
    const [subHashtagObjectCounter, setSubHashtagObjectCounter] = useState();
    const [subHashtagActive, setSubHashtagActive] = useState();

    const [subHashtagMinAmount, setSubHashtagMinAmount] = useState();
    const [subHashtagMaxAmount, setSubHashtagMaxAmount] = useState();


    const {
        state: dataSubHashtags,
        loading: subHashtagLoading,
        error: subHashtagError,
        subHashtag,
        setSubHashtag
    } = useHashtagsFetch();

    if(subHashtag !== true) {
        setSubHashtag(true);
    }

    if(dataSubHashtags[0] && !subHashtagObject[0]) {
        setSubHashtagObject(dataSubHashtags);
    }
    
    if(subHashtagObject) {

        if(subHashtagObjectCounter) {
            subHashtagObject[subHashtagObjectCounter].active = subHashtagActive;
        }

    }
    /* Sub Hashtag Stuff End */

    /* Hashtag Create Stuff */
    const [title, setTitle] = useState(' ');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);


    const handleInputHashtag= e => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        if(name === 'hashtagTextareaTitle') setTitle(value);
        
    };

    const handleSubmitHashtag = async () => {
        if(title) {
            try {
                setLoading(true)
                setError(false);
                setHashtagMinAmount(null)
                setHashtagMaxAmount(null)
                setErrorMessage(null)
        
                /* check some things */
                    var newTitle = title;
                    /* check if title ends with space */

                        if(/\s$/.test(newTitle)) {
                            var newTitle = (newTitle.substring(0, newTitle.length - 1))
                        }
                    /* check if title ends with space End */
                    
                    /* setTitle after checks if something changed */
                    if(newTitle != title) {
                        setTitle(newTitle);
                    }
                    /* setTitle after checks if something changed End */
                /* check some things end */
                    const data = await API.updateHashtag('createHashtag', UserFunctions.getSessionHash(), newTitle, hashtagObject);
            
                    console.log(data.results[0]);
                    
                    var hashtag = data.results[0].hashtag;
                    var success = data.results[0].success;
                    var error = data.results[0].error;
                    var errorMessage = data.results[0].errorMessage;

                    if(success) {
                        if(hashtag) { 
                            setLoading(false);
                            navigate('/category/' + hashtag);
                        }
                    }

                    if(error) {
                        if(errorMessage) {
                            setLoading(false);
                            setErrorMessage(errorMessage)
                        }
                    }

            } catch (error) {
                setLoading(false);
                setError(true);
            }
        }
    };


    /* Sub Hashtag Create Stuff */
    const [subTitle, setSubTitle] = useState(' ');
    const [subError, setSubError] = useState(false);
    const [subErrorMessage, setSubErrorMessage] = useState('');
    const [subLoading, setSubLoading] = useState(false);

    const handleInputSubHashtag= e => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        if(name === 'subHashtagTextareaTitle') setSubTitle(value);
        
    };

    const handleSubmitSubHashtag = async () => {
        if(subTitle) {
            try {
                setSubLoading(true)
                setSubError(false);
                setSubHashtagMinAmount(null)
                setSubHashtagMaxAmount(null)
                setSubErrorMessage(null)
        
                /* check some things */
                    var newSubTitle = subTitle;
                    /* check if title ends with space */

                        if(/\s$/.test(newSubTitle)) {
                            var newSubTitle = (newSubTitle.substring(0, newSubTitle.length - 1))
                        }
                    /* check if title ends with space End */
                    
                    /* setTitle after checks if something changed */
                    if(newSubTitle != subTitle) {
                        setSubTitle(newSubTitle);
                    }
                    /* setTitle after checks if something changed End */
                /* check some things end */
                    const data = await API.updateHashtag('createSubHashtag', UserFunctions.getSessionHash(), newSubTitle, subHashtagObject);
            
                    console.log(data.results[0]);
                    
                    var hashtag = data.results[0].hashtag;
                    var success = data.results[0].success;
                    var error = data.results[0].error;
                    var errorMessage = data.results[0].errorMessage;

                    if(success) {
                        if(hashtag) { 
                            setLoading(false);
                            navigate('/category/' + hashtag);
                        }
                    }

                    if(error) {
                        if(errorMessage) {
                            setLoading(false);
                            setErrorMessage(errorMessage)
                        }
                    }

            } catch (error) {
                setLoading(false);
                setError(true);
            }
        }
    };

    useEffect(() => {
        if(!loading) {
            if(title) {
                return;
            }
        }

    }, [title, subTitle, value, loading]);

        /* Fill Title Field with Search Value */
        if(title == ' ') {
            setTitle(value);
        }
        if(subTitle == ' ') {
            setSubTitle(value);
        }
        /* Fill Title Field with Search Value End */
    /* Hashtag Create End */

    if(loading) return <Spinner />
    if(error) return <Wrapper>Something went wrong...</Wrapper>

    return (
        
        <Wrapper>
            {
                (errorMessage) ?
                <Wrapper>
                    <GetText textName={errorMessage} textStyle={'default'} />
                    {
                        (hashtagMinAmount) ?
                            <> <GetText textName={'minAmount'} textStyle={'default'} valueAfter={hashtagMinAmount} /> </>
                        :null
                    }
                    {
                        (hashtagMaxAmount) ?
                            <> <GetText textName={'maxAmount'} textStyle={'default'} valueAfter={hashtagMaxAmount} /> </>
                        :null
                    }
                </Wrapper> :null
            }
			<div className="hashtag-create-wrapper">
                {
                    <>
                        <div>
                            <div className="hashtag-create-title"><GetText textName='hashtag' /></div>
                            <Textarea name="hashtagTextareaTitle" className='hashtag-create-textarea-title"' placeholder='' value={title} onChange={handleInputHashtag} />
            
                            <span id="hashtagTextareaTitleCount" className="hashtag-create-textarea-title-count"></span>
                            <textarea name="hashtagTextareaText" className="hashtag-create-textarea-text" placeholder=''></textarea>
                            <center><Button className='hashtag-create-button color-button hover' text={<GetText textName='create' />} callback={handleSubmitHashtag} /></center>
                        </div>
                        <div>
                            {
                                (hashtagObject) ? (
                                <h2><GetText textName='hashtags' /></h2>
                                )
                                :
                                (
                                    <></>
                                )
                            }
                            {
                                (hashtagObject) ?
                                    Object.keys(hashtagObject).map((a) => (
                                        <Link to={`/category/${hashtagObject[a].hashtag}`}>
                                            <Hashtag key={hashtagObject[a].hashtagId}
                                            name={a}
                                            text={ `${(hashtagObject[a].hidden == true) ? hashtagObject[a].hashtag+' [Hidden]' : hashtagObject[a].hashtag }`}
                                            disabled={ `${(hashtagObject[a].disabled == true) ? true : false}`} />
                                        </Link>
                                    ))
                                :null
                            }
                        </div>
                        <br></br>
                        <div>
                            <div className="hashtag-create-title">Sub <GetText textName='hashtag' /></div>
                            <Textarea name="subHashtagTextareaTitle" className='hashtag-create-textarea-title"' placeholder='' value={subTitle} onChange={handleInputSubHashtag} />
            
                            <span id="hashtagTextareaTitleCount" className="hashtag-create-textarea-title-count"></span>
                            <textarea name="subHashtagTextareaText" className="hashtag-create-textarea-text" placeholder=''></textarea>
                            <center><Button className='hashtag-create-button color-button hover' text={<GetText textName='create' />} callback={handleSubmitSubHashtag} /></center>
                        </div>
                        <div>
                            {
                                (subHashtagObject) ? (
                                <h2>Sub <GetText textName='hashtags' /></h2>
                                )
                                :
                                (
                                    <></>
                                )
                            }
                            {
                                (subHashtagObject) ?
                                    Object.keys(subHashtagObject).map((a) => (
                                        <Link to={`/category/${subHashtagObject[a].hashtag}`}>
                                            <Hashtag key={subHashtagObject[a].hashtagId}
                                            name={a}
                                            text={ `${(subHashtagObject[a].hidden == true) ? subHashtagObject[a].hashtag+' [Hidden]' : subHashtagObject[a].hashtag }`}
                                            disabled={ `${(subHashtagObject[a].disabled == true) ? true : false}`} />
                                        </Link>
                                    ))
                                :null
                            }
                        </div>
                    </>
                }
            </div>
        </Wrapper>
    )
};

export default CreateHashtag;
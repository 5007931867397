import { useState, useEffect } from 'react';
// API
import API from '../API';

export const useUserlistFetch = (userHash) => {
    const [state, setState] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const fetchUserlist = async() => {
        try {
            setLoading(true);
            setError(false);

            const data = await API.userList(userHash.userHash);

            setState(data);
            setLoading(false);

        } catch (error) {
            setLoading(false);
            setError(true);
        }
    };
        

    useEffect(() => {
        fetchUserlist();
    }, [userHash])

    return { state, loading, error };
}
import { useState, useEffect } from 'react';
// API
import API from '../API';

export const useHashtagsFetch = () => {
    const [state, setHashtags] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [action, setAction] = useState('');
    const [hashtagSelectedName, setHashtagSelectedName] = useState('');
    const [subHashtag, setSubHashtag] = useState(false);

    
    

    const fetchHashtags = async(getAction, getHashtagMainName, getSubHashtag) => {
        try {
            setLoading(true);
            setError(false);

            const data = await API.fetchHashtags(getAction, getHashtagMainName, getSubHashtag);

            setHashtags({
                ...data,
            });

            setLoading(false);

        } catch (error) {
            setLoading(false);
            setError(true);
        }
    };
        

    useEffect(() => {
        fetchHashtags(action, hashtagSelectedName, subHashtag);
    }, [action, hashtagSelectedName, subHashtag])

    return { state, loading, error,
        hashtagSelectedName, setHashtagSelectedName,
        subHashtag, setSubHashtag,
        action, setAction };
}
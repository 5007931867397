import styled from 'styled-components';

export const ProfileHeader = styled.div`
display:block;
width:100%;
`;

export const UserImage = styled.div`
display:block;
margin:auto;
text-align:center;
width:100%;
height:100%;
max-width: 250px;
max-height: 250px;
overflow: hidden;
`;

export const Wrapper = styled.div`
/* Profile  */
.profile {
    cursor:default;
    width: 100%;
    min-height:400px;
    position: relative;
    margin-top: 32px;
    }
    .profile-right {
    width: 100%;
    max-width: var(--maxWidth);
    margin-top:0px;
    display:inline-block;
    vertical-align:top;
    }
    .profile-header {
    width:100%;
    padding: 25px;
    border-radius:9px;
    overflow:hidden;
    background: var(--color-bg-2);
    box-shadow: var(--box-shadow-1);
    }
    .profile-img {
    margin: auto;
    width:200px;
    height:200px;
    border-radius: 100%;
    }
    .profile-username, .profile-email {
    margin-top:25px;
    border-radius:3px;
    background:var(--color-bg-2);
    border:1px solid;
    border-color: var(--color-border-5);
    padding:5px;
    }
    .userVerifiedIcon {
        width:26px;
        margin:2px;
    }
    .profile-levelbar-wrapper{
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    }
    .profile-levelbar {
    width: 100%;
    height: 50px;
    position: relative;
    border-radius: 12px;
    border:2px solid;
    border-color: var(--color-border-1);
    overflow:hidden;
    }
    .profile-levelbar-progress {
    height: 110%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    background: var(--color-bg-1);
    margin-left:-1px;
    }
    .profile-levelbar-informations {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    width: 100%;
    font-size: 18px;
    }
    .profile-levelbar-level {
    height: 100%;
    position:absolute;
    display: inline-table;
    left:5%;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-5);
    font-weight: bold;
    }
    .profile-levelbar-text {
    height: 100%;
    position:absolute;
    display: inline-table;
    right:5%;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-5);
    }
    .profile-informations-wrapper {
    float:left;
    width:100%;
    }
    .profile-informations-row {
    width:100%;
    display: inline-block;
    }
    .profile-informations-container {
    display:inline-block;
    width: 100%;
    max-width: 100%;
    min-height:245px;
    padding: 25px;
    margin-top: 25px;
    border-radius:9px;
    overflow:hidden;
    background: var(--color-bg-2);
    box-shadow: var(--box-shadow-1);
    vertical-align: top;
    }
    .profile-informations-title {
    font-weight:bold;
    color: var(--color-5);

        a {
            color: var(--color-5);
        }
    }
    .profile-informations-amount-wrapper {
    color: var(--color-1);
    }
    .profile-informations-amount-container {
    display: inline-block;
    text-align: center;
    max-width: min-content;
    vertical-align:top;
    padding:25px;

        :hover {
            opacity: 0.75;
        }
    }
    .profile-informations-amount {
    font-size: 46px;
    font-weight: bold;
    }
    .profile-informations-amount-title {
    font-size: 16px;
    }
    .profile-settings-img-upload {
    width:100%;
    margin-top:25px;
    }
    /* Profile END */
    `;
import React, { useState } from 'react';
import API from '../../API';
// Config
import { USER_IMG_URL } from '../../config'
//Functions
import UserFunctions from '../UserFunctions';
//Components
import Input from '../Input';
import Button from '../Button';
import Spinner from '../Spinner';
import PostInfo from '../PostInfo';
import GetText from '../GetText';
import UserImage from '../UserImage';
import UserDefaultImage from '../../images/user/profile-img-default.png'
import Moderation from '../Moderation';
//Hook
import { useUserFetch } from '../../hooks/useUserFetch';
// Styles
import { Wrapper, ProfileHeader } from './UserInfo.styles';

const UserInfo = ({getUserName, getUserHash}) => {


    const [userId, setUserId] = useState(0);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userVerified, setUserVerified] = useState(false);
    const [userBanned, setUserBanned] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const [success, setSuccess] = useState(false);

    const handleSubmit = async () => {

        try {
            setSuccess(false);

            const updateUser = await API.updateUser(getUserHash, userId, userName, userEmail, userVerified, userBanned);

            if(updateUser.results[0].success === true) {
                setSuccess(true);
            }

        } catch (error) {
            setSuccess(false);
        }
    };

    const {
        state,
        loading, 
        error,
        notFound
    } = useUserFetch(getUserName, getUserHash);

    if(loading) {
        if(!firstLoad) setFirstLoad(true)
        return <Spinner />
    }
    
    if(error) return <div>Something went wrong...</div>
    if(notFound) return <div>Not found...</div>

    // Levelbar
    var this_level_xp = state.userLevelMinXp;
    var next_level_xp = state.userLevelMaxXp;
    var user_xp = state.userCurrentXp;
    
    var xp_diff = next_level_xp - this_level_xp;//get diff between this two levels
    var current_xp = user_xp - this_level_xp;//calculate how much xp I did this level
    var current_percent = Math.floor((100 * current_xp) / xp_diff);//calculate how much percent I got in this level
    //Levelbar End

    const handleUserVerified = e => {
        var valueUserVerified = e.currentTarget.value;

        if(valueUserVerified != null) {

            setUserVerified(valueUserVerified);
        }
    }

    const handleUserBanned = e => {
        var valueUserBanned = e.currentTarget.value;

        if(valueUserBanned != null) {

            setUserBanned(valueUserBanned);
        }
    }

    if((state) && (!loading) && (firstLoad)) {
        if(userId != state.userId) {
            setUserId(state.userId);
        }
        if(userName != state.userName) {
            setUserName(state.userName);
        }
        if(userEmail != state.userEmail) {
            setUserEmail(state.userEmail);
        }
        if(!userVerified) {
            if(state.userVerified == false) {
                setUserVerified('false');
            }
            if(state.userVerified == true) {
                setUserVerified('true');
            }
        }
        if(!userBanned) {

            if(state.userBanned == false) {
                setUserBanned('false');
            }
            if(state.userBanned == true) {
                setUserBanned('true');
            }
        }
        setFirstLoad(false);
    }

    return (
        <>
            <Wrapper>
                <div className="profile">
                    <div className="profile-right">
                        <div id="profileHeader" className="profile-header">
                            <h3>
                                {
                                    success ?
                                            <span className='successTrue'>{<GetText textName='saved' />}</span>
                                    :null
                                }
                            </h3>
                            <Moderation paramUserId={userId} paramUserHash={UserFunctions.getSessionHash()}/>
                            <UserImage src={(state.userImgUrl) ? ( USER_IMG_URL + state.userImgUrl ) : ( UserDefaultImage )} alt='UserImage' className='profile-img'/>
                            <ProfileHeader>
                                <GetText textName={'userName'} />
                                <Input className="profile-username" value={userName} onChange={event => setUserName(event.currentTarget.value)} />
                                <GetText textName={'email'} />
                                <Input className="profile-email" value={userEmail} onChange={event => setUserEmail(event.currentTarget.value)} />
                                {
                                    (state.userVerified == true) ? 
                                        <select onChange={handleUserVerified}>
                                            <option value='true'>Verified</option>
                                            <option value='false'>Not Verfied</option>
                                        </select>
                                    :
                                        <select onChange={handleUserVerified}>
                                            <option value='false'>Not Verfied</option>
                                            <option value='true'>Verfied</option>
                                        </select>
                                }
                                <br></br>
                                <br></br>
                                {
                                    (state.userBanned == true) ? 
                                        <select onChange={handleUserBanned}>
                                            <option value='true'>Banned</option>
                                            <option value='false'>Not Banned</option>
                                        </select>
                                    :
                                        <select onChange={handleUserBanned}>
                                            <option value='false'>Not Banned</option>
                                            <option value='true'>Banned</option>
                                        </select>
                                }
                                <Button type='button' text={<GetText textName='save' />} callback={handleSubmit} />
                                <div className="profile-levelbar-wrapper">
                                    <div className="profile-levelbar">
                                        <div id="levelbar-progress" className="profile-levelbar-progress" style={{width: current_percent+'%'}}>
                                        </div>
                                        <div className="profile-levelbar-informations">
                                            <div className="profile-levelbar-level"><GetText textName='user_level' /> <span id="levelAmount">{state.userLevel}</span></div>
                                            <div className="profile-levelbar-text"><span id="profile-levelbar-percent">{100-current_percent+'%'}</span> <GetText textName='user_up_to_level' /> <span id="levelNextAmount">{state.userNextLevel}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </ProfileHeader>
                        </div>
                        <div id="profileInformations" className="profile-informations-wrapper">
                            <div className="profile-informations-row">
                                <div className="profile-informations-container">
                                    <div className="profile-informations-title"><GetText textName='user_stats' /></div>
                                    <div className="profile-informations-amount-wrapper">
                                        <center>
                                            <div className="profile-informations-amount-container">
                                                <div id="postAmount" className="profile-informations-amount">{state.userPostAmount}</div>
                                                <div className="profile-informations-amount-title"><GetText textName='user_questions' /></div>
                                            </div>
                                            <div className="profile-informations-amount-container">
                                                <div id="postAnswerAmount" className="profile-informations-amount">{state.userPostAnswerAmount}</div>
                                                <div className="profile-informations-amount-title"><GetText textName='user_answers' /></div>
                                            </div>
                                            <div className="profile-informations-amount-container">
                                                <div id="postAnswerActiveAmount" className="profile-informations-amount">{state.userPostActiveAnswerAmount}</div>
                                                <div className="profile-informations-amount-title"><GetText textName='user_active_answers' /></div>
                                            </div>
                                        </center>
                                    </div>
                                </div>
                                <div className="profile-informations-container">
                                {
                                    <>
                                        <div className="profile-informations-title"><GetText textName='user_last_question' /></div>
                                        <hr/>
                                        {
                                            state.userPostLatest.map(row => (
                                                <PostInfo key={row.id} postData={row} userHash={UserFunctions.getSessionHash()} />
                                            ))
                                        }
                                    </>
                                }
                                </div>
                                <div className="profile-informations-container color-bg-2 float-right box-shadow-1">
                                {
                                    <>
                                        <div className="profile-informations-title"><GetText textName='user_last_answer' /></div>
                                        <hr/>
                                        {
                                            state.userAnswerLatest.map(row => (
                                                <PostInfo key={row.id} postData={row} userHash={UserFunctions.getSessionHash()} />
                                            ))
                                        }
                                    </>
                                }
                                </div>
                            </div>
                            <div className="profile-informations-row">
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    )
};

export default UserInfo;
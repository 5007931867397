import styled from "styled-components";

export const Wrapper = styled.div`
max-width: var(--superMediumWidth);
width:100%;
margin:auto;
padding: var(--padding-left-right);

.post-wrapper {
    margin: 12px 0;
}
`;
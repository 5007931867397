import styled from "styled-components";

export const Wrapper = styled.div`
    max-width: var(--superMediumWidth);
    margin:auto;
    padding-top:20px;

    /* Hashtag Create */
    .hashtag-create-title {
    width: max-content;
    padding: 12px;
    font-weight: bold;
    font-size: 18px;
    border-radius: 6px;
    color: var(--color-1);
    background: var(--color-bg-2);
    box-shadow: var(--box-shadow-1);
    }
    .hashtag-create-textarea-title {
    width: 100%;
    min-height: 75px;
    margin-top: 1.5vh;
    padding: 12px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    color: var(--color-5);
    background: var(--color-bg-2);
    box-shadow: var(--box-shadow-1);
    }
    .hashtag-create-textarea-title-count {
    position: relative;
    bottom: 30px;
    left: 5px;
    color: red;
    }
    .hashtag-create-textarea-text {
    display:none;
    width: 100%;
    min-height: 150px;
    margin-top: 1.5vh;
    padding: 12px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    color: var(--color-1);
    background: var(--color-bg-2);
    box-shadow: var(--box-shadow-1);
    }
    .disabled {
        opacity:0.25 !important;
        cursor:not-allowed;

        :hover {
            opacity:0.25 !important;
            cursor:not-allowed;
        }
    }
    a {
        color: var(--color-1);
    }
    textarea {
        resize: vertical;
    }
    /* Post Create END */
`;
import React from 'react';
import { Link } from 'react-router-dom';
//Functions
import UserFunctions from '../UserFunctions';
//Components
import GetText from '../GetText';
import Logo from '../../images/logo/logo.png'
import LogoOld from '../../images/logo/logo.svg';
import LogoDarkMode from '../../images/logo/logoDarkMode.svg';

import {Wrapper, Content, LogoImg } from './Header.styles';

const Header = ( data ) => {
    const [state, setUser] = React.useState({ name: data.userName });

    /* Language */

    if(UserFunctions.getUserLanguage() == null) {

        var userLang = navigator.language || navigator.userLanguage; // de, en, ...
        UserFunctions.setUserLanguage(userLang);

    }

    /* Language End */

    return (
      <>
        <Wrapper id="header">
            <Content>
                <Link to='/'>
                    {
                    (UserFunctions.getDarkMode() == 'true') ?
                            <LogoImg src={Logo} alt='logo' />
                        :
                            <LogoImg src={Logo} alt='logo' />
                    }
                </Link>
                <HeaderUser data={state} />
            </Content>
        </Wrapper>
      </>
    );
};

const HeaderUser = ({ data }) => (

    data.name ? (
        <>
            <div className='iconWrapper'>
                <Link to='/dashboard'><i className="fas fa-user-shield adminIcon"></i></Link>
                <Link to={`/@${data.name}`}><i className="fas fa-user userIcon"></i></Link>
                <Link to='/settings'><i className="fas fa-tools settingsIcon"></i></Link>
                <Link to='/logout'><i className="fas fa-sign-out-alt logoutIcon"></i></Link>
            </div>
        </>
    ) : (
        <Link to='/login'>
            <button className="signin-button hover"><i className="far fa-user-circle signinIcon"></i><span><GetText textName='navigation_signin' /></span></button>
        </Link>
    )
);
export default Header;
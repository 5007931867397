import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
//API
import API from '../../API';
//Components
import GetText from '../GetText';
import Spinner from '../Spinner';
import Textarea from '../Textarea';
import Button from '../Button';
import Image from '../Image';
import Input from '../Input';
//Styles
import { Wrapper } from './EditImage.styles';
//Hook
import { useImagesFetch } from '../../hooks/useImagesFetch';
import UserFunctions from '../UserFunctions';
//Config
import { IMG_URL} from '../../config';

const UpdateImage = ({ getImageId, getFullFileName }) => {

    /* Get Images */
    const [imageObject, setImageObject] = useState('');
    const [imageObjectCounter, setImageObjectCounter] = useState();
    const [imageActive, setHashtagActive] = useState();

    const {
        state: dataImages,
        loading: imageLoading,
        error: imageError,
    } = useImagesFetch();

    if(dataImages[0] && !imageObject[0]) {
        setImageObject(dataImages);
    }
    /* Get Images END */

    /* Images Edit */
    const [imageId, setImageId] = useState(' ');
    const [fullFileName, setFullFileName] = useState(' ');
    const [newFullFileName, setNewFullFileName] = useState(' ');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleInputFullFileName = e => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        if(name === 'fullFileName') setFullFileName(value);
        
    };

    const handleUpdateFullFileName = async () => {

        if(fullFileName) {
            try {
                setLoading(true)
                setError(false);
                setErrorMessage(null)
        
                /* check some things */
                    var newFullFileName = fullFileName;
                    /* check if title ends with space */

                        if(/\s$/.test(newFullFileName)) {
                            var newFullFileName = (newFullFileName.substring(0, newFullFileName.length - 1))
                        }
                    /* check if title ends with space End */
                /* check some things end */

                    const data = await API.updateImage('updateFullFileName', UserFunctions.getSessionHash(), imageId, getFullFileName, newFullFileName);
                    
                    var resultImageId = data.results[0].imageId;
                    var resultFullFileName = data.results[0].fullFileName;
                    var errorMessage = data.results[0].error;

                    if(resultFullFileName) { 
                        setLoading(false);
                        navigate('/image/'+resultImageId+'/'+resultFullFileName);
                    }

                    if(errorMessage) {
                        setLoading(false);
                        setErrorMessage(errorMessage)
                    }


            } catch (error) {
                setLoading(false);
                setError(true);
            }
        }
    };

    const handleDeleteImage = async () => {
        if(fullFileName) {
            try {
                setLoading(true)
                setError(false);
                setErrorMessage(null)


                const data = await API.updateImage('deleteImage', UserFunctions.getSessionHash(), imageId, getFullFileName, newFullFileName);
                    
                var success = data.results[0].success;
                var errorMessage = data.results[0].error;

                if(success) { 
                    setLoading(false);
                    navigate('/image');
                }

                if(errorMessage) {
                    setLoading(false);
                    setErrorMessage(errorMessage)
                }


            } catch (error) {
                setLoading(false);
                setError(true);
            }
        }
    };

    useEffect(() => {
        if(!loading) {
            if(fullFileName) {
                return;
            }
        }

    }, [fullFileName, newFullFileName, getImageId, getFullFileName, loading]);

    /* Images Edit End */


    /* Fill Title Field with Search Value */
    if(fullFileName == ' ') {
        setFullFileName(getFullFileName);
    }
    if(imageId == ' ') {
        setImageId(getImageId);
    }
    /* Fill Title Field with Search Value End */




    if(loading) return <Spinner />
    if(error) return <Wrapper>Something went wrong...</Wrapper>


    return (
        
        <Wrapper>
            <>
                <Link to='/image'><GetText textName={'back'} /></Link>
            </>
            {
                (errorMessage) ?
                <Wrapper>
                    <GetText textName={errorMessage} textStyle={'default'} />
                </Wrapper> :null
            }
			<div className="hashtag-create-wrapper">
                {
                    <>
                        <div className="hashtag-create-title"><GetText textName='Image' /> ID: {imageId}</div>
                        <Textarea name="fullFileName" className='hashtag-create-textarea-title"' placeholder='' value={fullFileName} onChange={handleInputFullFileName} />
                        <center><Button className='hashtag-create-button color-button hover' text={'Update FullFileName'} callback={handleUpdateFullFileName} /></center>
                        <center><Button className='hashtag-create-button color-button hover' text={<GetText textName='delete' /> } callback={handleDeleteImage} /></center>
                        {
                            
                            (imageObject) ? (
                                <h2><GetText textName='images' /></h2>
                            )
                            :
                            (
                                <></>
                            )
                            
                        }
                        {

                            (imageObject) ?
                                Object.keys(imageObject).map((a) => (
                                    <Link to={`/image/${imageObject[a].imageId}/${imageObject[a].fullFileName}`}>
                                        <Image key={imageObject[a].imageId}
                                            src={IMG_URL + imageObject[a].fullFileName} alt='Image' />
                                    </Link>
                                ))
                            :null

                        }
                    </>
                }
            </div>
        </Wrapper>
    )
};

export default UpdateImage;
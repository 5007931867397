import React from 'react';
//Styles
import { Wrapper, Img } from './Image.styles';

const Image = ({ src, onClick, active, disabled }) => (
    <Wrapper>
        {
            (disabled == 'true') ?
                <Img src={src} className={ 'disabled' } />
            :
                <Img src={src} className={ `${(active == 'true') ? 'hover hashtagActive' : 'hover'}`} />
        }
    </Wrapper>
);

export default Image;
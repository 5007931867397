import React from 'react';
import { useParams } from 'react-router-dom';
//Functions
import UserFunctions from './UserFunctions';
import Wrapper from './Wrapper'
// Components
import UserInfo from './UserInfo';
import Header from './Header';
import Footer from './Footer';
import { Wrapper as ProfileWrapper } from './Profile/Profile.styles';

const Profile = () => {

    const {
        userName
    } = useParams();
    
    return (
        <>
            <Header userName={UserFunctions.getUserName()} />
            <Wrapper>
                <ProfileWrapper>
                    <UserInfo getUserName={userName} getUserHash={UserFunctions.getSessionHash()}/>
                </ProfileWrapper>
            </Wrapper>
            <Footer />
        </>
    )
};

export default Profile;
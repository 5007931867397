import { useState, useEffect } from 'react';
import UserFunctions from '../components/UserFunctions';
// API
import API from '../API';

export const useImagesFetch = () => {
    const [state, setImages] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [imageId, setImageId] = useState('');
    const [imageSelectedName, setImageSelectedName] = useState('');

    
    

    const fetchImages = async(getImageId, getFullFileName) => {
        try {
            setLoading(true);
            setError(false);

            const data = await API.fetchImages(UserFunctions.getSessionHash(), getImageId, getFullFileName);

            setImages({
                ...data,
            });

            setLoading(false);

        } catch (error) {
            setLoading(false);
            setError(true);
        }
    };
        

    useEffect(() => {
        fetchImages(imageId, imageSelectedName);
    }, [imageId, imageSelectedName])

    return { state, loading, error,
        imageId, setImageId, imageSelectedName, setImageSelectedName };
}
import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
//Components
import Spinner from '../Spinner';
import Vote from '../Vote';
import Moderation from '../Moderation';
import GetText from '../GetText';
import Hashtag from '../Hashtag';
// Styles
import { Wrapper as PostInfoWrapper, Content } from './PostInfo.styles';

const PostInfo = ({ showHashtag, postData, userHash, onClick }) => {

    const {
        loading,
        error
    } = useParams();
    
    const navigate = useNavigate();

    if(loading) return <Spinner />
    if(error) return <div>Something went wrong...</div>

    if(!postData.id) {
        navigate('/p/');
    }

    return (
        <>
            <PostInfoWrapper onClick={onClick}>
                {
                    (postData.hashtags && showHashtag == true) ?
                        Object.keys(postData.hashtags).map((a) => (
                            <>
                                <Link to={`/!${encodeURIComponent(postData.hashtags[a].hashtag)}`}>
                                    <Hashtag key={postData.hashtags[a].hashtagId}
                                    text={postData.hashtags[a].hashtag}
                                    />
                                </Link>
                            </>
                        ))
                    :null
                }
                <Content>
                    {
                        (postData.id) ? (
                            <article key={postData.id}>
                                <div className="post-wrapper post-id box-shadow-1">
                                    <div className='postModeration'>
                                        <Moderation paramPostId={postData.id} paramAnswerId={postData.answerId} paramUserHash={userHash} post={postData.title} answer={postData.answer} />
                                    </div>
                                    <Link to={`/p/${postData.url}`} className='hover'>
                                        <h1 id="postTitle" className="post-title">
                                        {postData.title} ~{postData.postUserName} [{postData.dateDMY}]
                                        </h1>
                                        <div id="postText" className="post-text">
                                            <p>{postData.text}</p>
                                        </div>
                                    </Link>
                                    <div id="postAnswer" className="post-answer">
                                        {
                                            (postData.answerId) ? (
                                                <>
                                                    {
                                                        (postData.answer) ?
                                                            <>
                                                                <Link to={`/p/${postData.url}`} className='hover'><p>{postData.answer}</p></Link>
                                                                <div id="postAnswerVote" className="post-answer-vote">
                                                                    <Vote answerId={postData.answerId} userHash={userHash} />
                                                                </div>
                                                            </>
                                                        :null
                                                    }
                                                </>
                                            )
                                            :
                                            (
                                                <i><GetText textName='answerNotFound' /></i>
                                            )
                                        }
                                    </div>
                                    <span className='answerDate'>{(postData.answer) ? postData.answerDateDMY + ' ~' + postData.answerUserName :null}</span>
                                </div>
                        </article>
                        )
                        :
                        (
                            <></>
                        )
                    }
                </Content>
            </PostInfoWrapper>
        </>
    )
};

export default PostInfo;